import React from "react";

// Monacoin Balane
export async function getMonacoinBalance(address) {
  let balance;
    await window.mpurse.counterBlock('get_chain_address_info', { addresses: [address] })
    .then((r) => {
      balance = r[0].info.balance
    })
    .catch((e) => {});
    return balance;
}

// Address Short
export function addressShort(addr) {
  let marge;
  if (addr === "") {
    marge = "";
  } else {
    const start = addr.slice(0, 6);
    const end = addr.slice(-4);
    marge = `${start}...${end}`
  }
  return marge;
}

// Mpurse Address
export async function getAddressMP() {
  // let addressMP = "M9mHqvZTTw68AcLCXYu4V1Gs3coLTQQKa8";
  let addressMP = "";
  try {
    await window.mpurse.getAddress()
    .then((address) => {
      addressMP = address;
    })
    .catch(() => {
      return addressMP;
    });
  }
  catch {
    return addressMP;
  }
  return addressMP;
}

// Balance Info
export async function getAssetMP(addr) {
  const mpchainParams = {address: addr};
  let result;
  await window.mpurse.mpchain('balances', mpchainParams)
    .then((r) => {
      result = r.data;
  })
  .catch((e) => { return });
  return result;
}

// Balance Info Electrum
export async function getAssetsBalancesElectrum(addr) {
  let result;
  const data = {
    "jsonrpc":"2.0",
    "id":0,
    "method":"get_normalized_balances",
    "params": {
      "addresses": [addr]
    }
  }
  await fetch('https://monapa.electrum-mona.org/_api', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
  .then(response => response.json())
  .then((r) => {
    result = r.result;
    // console.log("b: " + result);
  })
  .catch((error) => { return });
  return result;
}

// Dispensers Info
export async function getDispensersMP() {
  let result;
  const data = {
    "jsonrpc":"2.0",
    "id":0,"method":"proxy_to_counterpartyd",
    "params":{
      "method":"get_dispensers",
      "params":{
        "limit":300,
        "order_by":"block_index",
        "order_dir":"DESC",
        "filters":[{
          "field":"status",
          "op":"==",
          "value":0
        }]
      }
    }
  }
  await fetch('https://mpchain.info/api/cb', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then((r) => {
      result = r.result;
    })
    .catch((error) => { return });
  return result;
}

// Asset Info
// AssetName は親トークン
// AssetLongName は子トークン
export async function getAssetsInfo() {
  let result;
  await getDispensersMP()
    .then((assetName) => {
      console.log('r: ' + assetName);
      fetch('https://monapa.electrum-mona.org/_api', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "jsonrpc": "2.0",
          "id": 0,
          "method": "get_assets_info",
          "params": { "assetsList": assetName }
        })
      })
      .then(response => response.json())
      .then((r) => {
        result = r.result;
      })
      .catch((error) => { console.log("getAssetsInfo error"); return } );
      return result;
    });
}

// Monacard Info
export async function getMonacard(assetName) {
  let result;
  await fetch('https://card.mona.jp/api/card_detail.php?assets=' + assetName, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
  })
  .then(response => response.json())
  .then((r) => {
    result = r.details;
  })
  .catch((error) => { console.log("getMonacard error"); return; } );
  return result;
}

export function getJoinedMonacardList(assetBalance) {
  let joinedMonacardName1;
  let joinedMonacardName2;
  let joinedMonacardName3;
  let joinedMonacardName4;
  let joinedMonacardName5;
  let joinedMonacardName6;
  let joinedMonacardName7;
  let joinedMonacardName8;
  let joinedMonacardName9;
  let joinedMonacardName10;
  let joinedMonacardNameList = [];
  
  for(let i = 0; i < assetBalance.length; i++){
    // Monacard 用に asset or assetlongname を取得
    let assetName = "";
    // トークンが FT の場合
    if(assetBalance[i].asset_longname === null) {
      assetName = assetBalance[i].asset;
    } else {
      assetName = assetBalance[i].asset_longname;
    }
      
    if(i < 250) {
      i === 0 ?
        joinedMonacardName1 = assetName :
        joinedMonacardName1 = joinedMonacardName1.concat(',', assetName);
    } else if(250 <= i && i < 500) {
      i === 250 ?
        joinedMonacardName2 = assetName :
        joinedMonacardName2 = joinedMonacardName2.concat(',', assetName);
    } else if(500 <= i && i < 750) {
      i === 500 ?
        joinedMonacardName3 = assetName :
        joinedMonacardName3 = joinedMonacardName3.concat(',', assetName);
    } else if(750 <= i && i < 1000) {
      i === 750 ?
        joinedMonacardName4 = assetName :
        joinedMonacardName4 = joinedMonacardName4.concat(',', assetName);
    } else if(1000 <= i && i < 1250) {
      i === 1000 ?
        joinedMonacardName5 = assetName :
        joinedMonacardName5 = joinedMonacardName5.concat(',', assetName);
    } else if(1250 <= i && i < 1500) {
      i === 1250 ?
        joinedMonacardName6 = assetName :
        joinedMonacardName6 = joinedMonacardName6.concat(',', assetName);
    } else if(1500 <= i && i < 1750) {
      i === 1500 ?
        joinedMonacardName7 = assetName :
        joinedMonacardName7 = joinedMonacardName7.concat(',', assetName);
    } else if(1750 <= i && i < 2000) {
      i === 1750 ?
        joinedMonacardName8 = assetName :
        joinedMonacardName8 = joinedMonacardName8.concat(',', assetName);
    } else if(2000 <= i && i < 2250) {
      i === 2000 ?
        joinedMonacardName9 = assetName :
        joinedMonacardName9 = joinedMonacardName9.concat(',', assetName);
    } else if(2250 <= i && i < 2500) {
      i === 2250 ?
        joinedMonacardName10 = assetName :
        joinedMonacardName10 = joinedMonacardName10.concat(',', assetName);
    }
  }
  joinedMonacardNameList.push(joinedMonacardName1);
  joinedMonacardNameList.push(joinedMonacardName2);
  joinedMonacardNameList.push(joinedMonacardName3);
  joinedMonacardNameList.push(joinedMonacardName4);
  joinedMonacardNameList.push(joinedMonacardName5);
  joinedMonacardNameList.push(joinedMonacardName6);
  joinedMonacardNameList.push(joinedMonacardName7);
  joinedMonacardNameList.push(joinedMonacardName8);
  joinedMonacardNameList.push(joinedMonacardName9);
  joinedMonacardNameList.push(joinedMonacardName10);

  return joinedMonacardNameList;
}

// Monacard Info
export async function getMonacardInfo(assetName) {
  let result;
  await fetch('https://card.mona.jp/api/card_detail.php?assets=' + assetName, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
  })
  .then(response => response.json())
  .then((r) => {
    result = r.details;
  })
  .catch((error) => { console.log("getMonacard error"); return; } );
  return result;
}

// Monacard Info Many
export async function getMonacardInfoMany(joinedAssetList) {
  // console.log("145: " + joinedAssetList);
  let monacardList = [];
  let result;

  for(let i = 0; i < joinedAssetList.length; i++) {
		// console.log("10: " + joinedAssetList.length);
    if(joinedAssetList[i] === undefined) break;
    await getMonacardInfo(joinedAssetList[i]).then((r) => { // Mpurse からのアセット情報で Monacard リクエスト
      monacardList[i] = r;
    }).then(() => {
      result = monacardList;
    });
  }

  return result;
}

// Monacard List
export async function getMonacardList() {
  let result;
  await fetch('https://card.mona.jp/api/card_list', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
  })
  .then(response => response.json())
  .then((r) => {
    result = r.list;
  })
  .catch((error) => { console.log("getMonacardList error"); return; } );
  return result;
}

export async function listDispensers() {
  let a;
  let list = [];
  await getDispensersMP().then(r => {
    for(let i in r){
      list.push(
        <div>
          <p>{r[i].reult.asset}</p>
          <p>{r[i].reult.block_index}</p>
          <p>{r[i].reult.escrow_quantity}</p>
          <p>{r[i].reult.give_quantity}</p>
          <p>{r[i].reult.give_remaining}</p>
          <p>{r[i].reult.satoshirate}</p>
          <p>{r[i].reult.source}</p>
          <p>{r[i].reult.status}</p>
          <p>{r[i].reult.tx_hash}</p>
          <p>{r[i].reult.tx_index}</p>
        </div>
      );
    }
  })
  return list;
}